const required = (v: any) => !!v || 'El campo es requerido';
export const permissionsForm = [
  {
    label: 'Nombre',
    type: 'v-text-field',
    rules: [required],
    field: 'name',
    format: 'col-12',
  },
  {
    label: 'Descripción',
    type: 'v-textarea',
    rules: [required],
    field: 'description',
    format: 'col-12',
  },
];
